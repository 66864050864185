<template>
  <div>
    <div class="users-table--actions">
      <ElementInput
        ref="searchInput"
        v-model="query"
        placeholder="Search"
        size="small"
        clearable
        class="users-table-teams__search"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
        />
      </ElementInput>
      <ElementButton
        type="primary"
        plain
        size="small"
        icon="el-icon-plus"
        class="users-table--add-user-button"
        @click="handleUserFormVisibility"
      >
        Add Members
      </ElementButton>
      <ElementButton
        type="primary"
        plain
        size="small"
        icon="el-icon-download"
        class="users-table--add-user-button"
        title="Excel export"
        :loading="isExportingUsers"
        @click="handleTeamUsersExport"
      />
    </div>

    <ElementTable
      v-loading="isLoadingUsers"
      :data="users"
      border
      stripe
      class="users-table"
      :cell-style="{ height: '55px' }"
      empty-text="Users not found."
      @sort-change="sortUsers"
    >
      <ElementTableColumn
        label="Email"
        prop="email"
        sortable
        width="350"
      />

      <ElementTableColumn
        label="Membership"
        prop="canManageUsers"
        width="200"
      >
        <template slot-scope="scope">
          <template v-if="edit !== scope.row.id">
            <ElementTag
              :type="scope.row.canManageUsers ? 'warning' : 'info'"
              size="mini"
              disable-transitions
            >
              {{ scope.row.canManageUsers ? 'Team Admin' : 'Member' }}
            </ElementTag>
          </template>
          <ElementSelect
            v-else
            v-model="userModel.canManageUsers"
            size="mini"
          >
            <ElementOption
              v-for="item in TEAM_MEMBERSHIP"
              :key="`${item.label}`"
              :label="item.label"
              :value="item.value"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Status"
        prop="isActive"
        width="200"
      >
        <template slot-scope="scope">
          <ElementTag
            :type="scope.row.isActive ? 'success' : 'danger'"
            size="mini"
            disable-transitions
          >
            {{ scope.row.isActive ? 'Active' : 'Inactive' }}
          </ElementTag>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Role"
        prop="role"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.role.display_name }}
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        align="center"
        width="160"
      >
        <template slot="header">
          Actions
        </template>
        <template slot-scope="scope">
          <ElementButton
            v-if="edit !== scope.row.id"
            :disabled="edit && edit !== scope.row.id"
            type="text"
            size="mini"
            class="email-editor__add el-input__icon"
            @click="onEdit(teamId, scope.row.id)"
          >
            <i class="el-icon-edit" />
          </ElementButton>
          <ElementButton
            v-else
            type="text"
            size="mini"
            class="email-editor__add el-input__icon"
            @click="onSave(teamId, scope.row.id)"
          >
            <i class="el-icon-check" />
          </ElementButton>
          <ElementButton
            v-if="edit === scope.row.id"
            type="text"
            size="mini"
            @click="onCancel"
          >
            <i class="el-icon-close" />
          </ElementButton>
          <ElementButton
            v-else
            :disabled="edit && edit !== scope.row.id"
            type="text"
            size="mini"
            @click="onDelete(teamId, scope.row.id)"
          >
            <i class="el-icon-delete" />
          </ElementButton>
        </template>
      </ElementTableColumn>
    </ElementTable>

    <ElementPagination
      align="right"
      layout="prev, pager, next"
      :total="usersCount"
      :per-page="limit"
      :current-page="currentPage"
      @current-change="setPage"
    />

    <StitchDialog
      title="Invite a new member to the team"
      :visible.sync="showNewInvitationForm"
      :show-close="true"
      append-to-body
      center
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      class="add-new-invitation__dialog"
    >
      <AddUserToTeamForm
        :existing-users="users"
        :current-team-id="teamId"
        @close-invitation-form="handleUserFormVisibility"
      />
    </StitchDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TEAM_MEMBERSHIP } from '@/constants/roleType'
import AddUserToTeamForm from './AddUserToTeamForm.vue'
import { SORTING_TYPE } from '@/constants/filterType'

export default {
  name: 'TeamUsersTable',

  components: {
    AddUserToTeamForm
  },

  props: {
    teamId: {
      type: Number,
      default: null,
      required: false
    },
    teamName: {
      type: String,
      default: null,
      required: false
    }
  },

  data () {
    return {
      TEAM_MEMBERSHIP,
      edit: null,
      query: '',
      isLoadingUsers: false,
      isExportingUsers: false,
      showActiveSelect: false,
      showTeamsModal: false,
      showNewInvitationForm: false,
      userModel: {
        userId: null,
        email: null,
        isActive: false,
        roleId: null,
        canManageUsers: false
      },
      currentPage: 1,
      sorting: null
    }
  },

  computed: {
    ...mapGetters({
      users: 'getUsersByTeam',
      usersCount: 'getUsersByTeamCount',
      limit: 'getLimit'
    })
  },

  watch: {
    /**
     */
    async query () {
      this.isLoadingUsers = true
      await this.fetchUsersByTeam({
        teamId: this.teamId,
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = 1
      this.isLoadingUsers = false
    },

    /**
     *
     */
    async teamId () {
      this.isLoadingUsers = true
      await this.fetchUsersByTeam({
        teamId: this.teamId,
        page: 1,
        sorting: this.sorting
      })
      this.isLoadingUsers = false
    }
  },

  async created () {
    if (this.teamId) {
      this.isLoadingUsers = true

      try {
        await this.fetchUsersByTeam({
          teamId: this.teamId,
          page: 1,
          sorting: this.sorting
        })
      } catch (error) {
        if (error.response) {
          // this.errorStatus = error.response.status
          console.log('Error fetching the users')
        } else {
          throw error
        }
      }

      this.isLoadingUsers = false
    }
  },

  methods: {
    ...mapActions([
      'fetchUsersByTeam',
      'updateMembershipForUser',
      'deleteUserFromTeam',
      'exportTeamUsers'
    ]),

    /**
     */
    async handleTeamUsersExport () {
      this.isExportingUsers = true
      await this.exportTeamUsers({
        teamId: this.teamId,
        teamName: this.teamName
      })
      this.isExportingUsers = false
    },

    /**
     *
     */
    handleUserFormVisibility () {
      this.showNewInvitationForm = !this.showNewInvitationForm
    },

    /**
     * @param {number} teamId
     * @param {number} userId
     */
    onEdit (teamId, userId) {
      const userToEdit = this.users.find(user => user.id === userId)

      this.userModel = {
        teamId: teamId,
        userId: userId,
        canManageUsers: userToEdit.canManageUsers
      }

      this.edit = this.edit !== userId ? userId : null
    },

    /**
     *
     */
    async onSave () {
      await this.updateMembershipForUser(this.userModel)
      this.onCancel()
    },

    /**
     *
     */
    onCancel () {
      this.userModel = {
        teamId: null,
        userId: null,
        canManageUsers: null
      }

      this.edit = null
    },

    /**
     * @param {number} teamId
     * @param {number} userId
     */
    onDelete (teamId, userId) {
      const userToDelete = this.users.find(user => user.id === userId)

      this.$confirm(
        `Are you sure you want remove the user "${userToDelete.email}" from the team?`,
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(async () => {
          await this.deleteUserFromTeam({ teamId, userId })
        })
        .catch(() => {})
    },

    /**
     * @param   {Array}    teams
     *
     * @returns {object[]}
     */
    formatTeams (teams) {
      const teamsToDisplay = teams.slice(0, 3)

      if (teams.length > 3) {
        teamsToDisplay.push('3 more')
      }

      return teamsToDisplay.map(team => team.name).join(', ')
    },

    /**
     *
     * @param { number } page
     */
    async setPage (page) {
      this.isLoadingUsers = true
      await this.fetchUsersByTeam({
        teamId: this.teamId,
        page: page,
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = page
      this.isLoadingUsers = false
    },

    /**
     * @param {string} prop
     * @param {string} order
     */
    async sortUsers ({ prop, order }) {
      this.sorting = order
        ? order === SORTING_TYPE.ASC
          ? prop
          : `-${prop}`
        : null

      this.isLoadingUsers = true
      await this.fetchUsersByTeam({
        teamId: this.teamId,
        page: this.currentPage,
        query: this.query,
        sorting: this.sorting
      })
      this.isLoadingUsers = false
    }
  }
}
</script>

<style lang="scss" scoped>
$users-table-teams-column-width: 250px;
$content-header-search-width: 210px;
$content-header-input-min-width: spacing(12);
$button-small-padding: 10px 15px;
$title-top: 12px;
$users-table-margin-top: 40px;
$total-members-border-radius: 8px;
$total-members-font-size: 14px;
$total-members-text-color: #909399;
$total-members-bg-color: #f0f2f5;

.users-table {
  width: 100%;
  height: 100%;
  margin-top: $users-table-margin-top;
}

.users-table--teams {
  max-width: $users-table-teams-column-width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users-table__action-edit {
  display: inline;
  color: $blue;
}

.users-table__action-delete {
  display: inline;
  color: $red;
}

.add-new-invitation__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.assign-teams__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.users-table--actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.users-table--actions h3 {
  margin-right: auto;
  font-weight: $font-weight-medium;
  font-size: spacing(3);
}

.users-table-teams__total {
  position: absolute;
  margin-top: spacing(8);
  margin-left: spacing(1);
  color: $total-members-text-color;
  font-size: $total-members-font-size;
}

.users-table-teams__search {
  width: auto;
  max-width: $content-header-search-width;
  margin-right: spacing(1);
  margin-left: auto;

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }
}
</style>

<style lang="scss">
.users-table .el-input--suffix .el-input__inner {
  height: spacing(3.8);
}

.el-table__header {
  height: spacing(7.5) !important;
}
</style>
