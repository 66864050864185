<template>
  <div>
    <ElementForm
      ref="assignTeamsForm"
      label-position="top"
      class="assign-teams__dialog-form"
      :model="assignTeamsForm"
      hide-required-asterisk
    >
      <ElementFormItem
        label="Teams"
        prop="teams"
        class="assign-teams__dialog-form-item"
      >
        <ElementSelect
          v-model="assignTeamsForm.teamsList"
          :disabled="!editTeams"
          multiple
          filterable
        >
          <ElementOption
            v-for="team in teams"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          />
        </ElementSelect>
      </ElementFormItem>
    </ElementForm>
    <div
      slot="footer"
      class="assign-teams__dialog-buttons"
    >
      <ElementButton
        v-if="editTeams"
        plain
        class="assign-teams__dialog-form__cancel"
        @click="closeAssignTeamsForm"
      >
        Cancel
      </ElementButton>
      <ElementButton
        v-if="editTeams"
        type="primary"
        plain
        class="assign-teams__dialog-form__submit"
        :loading="isLoadingTeams"
        @click="submitForm"
      >
        Done
      </ElementButton>
      <ElementButton
        v-else
        type="primary"
        plain
        class="assign-teams__dialog-form__submit"
        :loading="isLoadingTeams"
        @click="handleShowVendorTeamsForm"
      >
        Edit
      </ElementButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AssignTeamsForm',

  props: {
    vendorTeams: {
      type: Array,
      required: true
    },
    vendorId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      isLoadingTeams: false,
      assignTeamsForm: {
        teamsList: []
      },
      editTeams: false
    }
  },

  computed: {
    ...mapGetters({
      teams: 'getTeams'
    })
  },

  watch: {
    /**
     *
     */
    vendorTeams () {
      this.assignTeamsForm.teamsList = this.vendorTeams
    }
  },

  async created () {
    this.assignTeamsForm.teamsList = this.vendorTeams

    try {
      await this.fetchTeams()
    } catch (error) {
      if (error.response) {
        console.error('Something went wrong while fetching teams')
      } else {
        throw error
      }
    }
  },

  methods: {
    ...mapActions(['fetchTeams', 'changeUserTeams', 'updateVendorTeams']),

    /**
     */
    closeAssignTeamsForm () {
      this.editTeams = false
    },

    /**
     *
     */
    handleShowVendorTeamsForm () {
      this.editTeams = true
    },

    /**
     */
    async submitForm () {
      this.isLoadingTeams = true

      await this.updateVendorTeams({
        vendorId: this.vendorId,
        teamIds: this.assignTeamsForm.teamsList
      })

      this.isLoadingTeams = false
      this.editTeams = false
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-teams__dialog-buttons {
  text-align: center;
}

.assign-teams__dialog-form {
  width: spacing(50);
}

.assign-teams__dialog-form-item {
  /deep/ .el-form-item__label {
    padding: 0;
  }

  /deep/ .el-select {
    width: 100%;
  }
}
</style>
