<template>
  <div>
    <div class="users-table--actions">
      <ElementInput
        ref="searchInput"
        v-model="query"
        placeholder="Search"
        size="small"
        clearable
        class="users-table-vendors__search"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
        />
      </ElementInput>
      <ElementButton
        type="primary"
        plain
        size="small"
        icon="el-icon-plus"
        class="users-table-vendors--add-team-button"
        @click="handleAssignTeamFormVisibility"
      >
        Manage Teams
      </ElementButton>
    </div>

    <ElementTable
      v-loading="isLoadingUsers"
      :data="users"
      border
      stripe
      class="users-table"
      empty-text="Users not found."
      @sort-change="sortUsers"
    >
      <ElementTableColumn
        label="Email"
        prop="email"
        sortable
      />

      <ElementTableColumn
        label="Status"
        prop="isActive"
      >
        <template slot-scope="scope">
          <ElementTag
            :type="scope.row.isActive ? 'success' : 'danger'"
            size="mini"
            disable-transitions
          >
            {{ scope.row.isActive ? 'Active' : 'Inactive' }}
          </ElementTag>
        </template>
      </ElementTableColumn>
      <ElementTableColumn
        align="center"
        width="160"
      >
        <template slot="header">
          Actions
        </template>
        <template slot-scope="scope">
          <ElementButton
            type="text"
            size="mini"
            @click="onDelete(vendorId, scope.row.id)"
          >
            <i class="el-icon-delete" />
          </ElementButton>
        </template>
      </ElementTableColumn>
    </ElementTable>

    <ElementPagination
      align="right"
      layout="prev, pager, next"
      :total="usersCount"
      :per-page="limit"
      :current-page="currentPage"
      @current-change="setPage"
    />

    <StitchDialog
      title="Manage Teams"
      :visible.sync="showAssignTeamsForm"
      :show-close="true"
      append-to-body
      center
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      class="assign-teams__dialog"
    >
      <AssignVendorTeamsForm
        :vendor-teams="vendorTeamsModel"
        :vendor-id="vendorIdToAssign"
        @close-assign-teams-form="handleAssignTeamFormVisibility"
      />
    </StitchDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TEAM_MEMBERSHIP } from '@/constants/roleType'
import AssignVendorTeamsForm from '@/components/vendors/AssignVendorTeamsForm.vue'
import { SORTING_TYPE } from '@/constants/filterType'

export default {
  name: 'VendorUsersTable',

  components: { AssignVendorTeamsForm },

  props: {
    vendorId: {
      type: Number,
      default: null,
      required: false
    }
  },

  data () {
    return {
      TEAM_MEMBERSHIP,
      edit: null,
      query: '',
      isLoadingUsers: false,
      showActiveSelect: false,
      showVendorsModal: false,
      showAssignTeamsForm: false,
      vendorTeamsModel: [],
      vendorIdToAssign: null,
      userModel: {
        userId: null,
        email: null,
        isActive: false,
        roleId: null
      },
      currentPage: 1,
      sorting: null
    }
  },

  computed: {
    ...mapGetters({
      users: 'getUsersByVendor',
      usersCount: 'getUsersByVendorCount',
      limit: 'getLimit',
      activeVendor: 'getActiveVendor'
    })
  },

  watch: {
    /**
     */
    async query () {
      this.isLoadingUsers = true
      await this.fetchUsersByVendor({
        vendorId: this.vendorId,
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = 1
      this.isLoadingUsers = false
    },

    /**
     *
     */
    async vendorId () {
      this.isLoadingUsers = true
      await this.fetchUsersByVendor({
        vendorId: this.vendorId,
        page: 1,
        sorting: this.sorting
      })
      this.isLoadingUsers = false
    }
  },

  async created () {
    if (this.vendorId) {
      this.isLoadingUsers = true

      try {
        await this.fetchUsersByVendor({
          vendorId: this.vendorId,
          page: 1,
          sorting: this.sorting
        })
        await this.fetchVendorById({ vendorId: this.vendorId })
      } catch (error) {
        if (error.response) {
          // this.errorStatus = error.response.status
          console.log('Error fetching the users')
        } else {
          throw error
        }
      }

      this.isLoadingUsers = false
    }
  },

  methods: {
    ...mapActions([
      'fetchUsersByVendor',
      'deleteUserFromVendor',
      'fetchVendorById'
    ]),

    /**
     */
    handleAssignTeamFormVisibility () {
      this.vendorIdToAssign = this.vendorId
      this.vendorTeamsModel = this.activeVendor.teams

      this.showAssignTeamsForm = !this.showAssignTeamsForm
    },

    /**
     * @param {number} vendorId
     * @param {number} userId
     */
    onDelete (vendorId, userId) {
      const userToDelete = this.users.find(user => user.id === userId)

      this.$confirm(
        `Are you sure you want remove the user "${userToDelete.email}" from the vendor company?`,
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(async () => {
          await this.deleteUserFromVendor({ vendorId, userId })
        })
        .catch(() => {})
    },

    /**
     *
     * @param { number } page
     */
    async setPage (page) {
      this.isLoadingUsers = true
      await this.fetchUsersByVendor({
        vendorId: this.vendorId,
        page: page,
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = page
      this.isLoadingUsers = false
    },

    /**
     * @param {string} prop
     * @param {string} order
     */
    async sortUsers ({ prop, order }) {
      this.sorting = order
        ? order === SORTING_TYPE.ASC
          ? prop
          : `-${prop}`
        : null

      this.isLoadingUsers = true
      await this.fetchUsersByVendor({
        vendorId: this.vendorId,
        page: this.currentPage,
        query: this.query,
        sorting: this.sorting
      })
      this.isLoadingUsers = false
    }
  }
}
</script>

<style lang="scss" scoped>
$users-table-vendors-column-width: 250px;
$content-header-search-width: 210px;
$content-header-input-min-width: spacing(12);
$button-small-padding: 10px 15px;
$title-top: 12px;
$users-table-margin-top: 40px;
$total-members-border-radius: 8px;
$total-members-font-size: 14px;
$total-members-text-color: #909399;
$total-members-bg-color: #f0f2f5;

.users-table {
  width: 100%;
  height: 100%;
  margin-top: $users-table-margin-top;
}

.users-table--vendors {
  max-width: $users-table-vendors-column-width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users-table__action-edit {
  display: inline;
  color: $blue;
}

.users-table__action-delete {
  display: inline;
  color: $red;
}

.add-new-invitation__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.assign-vendors__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.users-table--actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.users-table--actions h3 {
  margin-right: auto;
  font-weight: $font-weight-medium;
  font-size: spacing(3);
}

.users-table-vendors__total {
  position: absolute;
  margin-top: spacing(8);
  margin-left: spacing(1);
  color: $total-members-text-color;
  font-size: $total-members-font-size;
}

.users-table-vendors__search {
  width: auto;
  max-width: $content-header-search-width;
  margin-right: spacing(1);
  margin-left: auto;

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }
}
</style>
