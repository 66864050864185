import { render, staticRenderFns } from "./AdminTools.vue?vue&type=template&id=81c01748&scoped=true&"
import script from "./AdminTools.vue?vue&type=script&lang=js&"
export * from "./AdminTools.vue?vue&type=script&lang=js&"
import style0 from "./AdminTools.vue?vue&type=style&index=0&id=81c01748&prod&lang=scss&scoped=true&"
import style1 from "./AdminTools.vue?vue&type=style&index=1&id=81c01748&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c01748",
  null
  
)

export default component.exports