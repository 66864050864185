<template>
  <div>
    <ElementForm
      ref="assignTeamsForm"
      label-position="top"
      class="assign-teams__dialog-form"
      :model="assignTeamsForm"
      :rules="validationRules"
      hide-required-asterisk
    >
      <ElementFormItem
        label="Teams"
        prop="teams"
        class="assign-teams__dialog-form-item"
      >
        <ElementSelect
          v-model="assignTeamsForm.teamsList"
          multiple
          filterable
        >
          <ElementOption
            v-for="team in teams"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          />
        </ElementSelect>
      </ElementFormItem>
    </ElementForm>
    <div
      slot="footer"
      class="assign-teams__dialog-buttons"
    >
      <ElementButton
        plain
        class="assign-teams__dialog-form__cancel"
        @click="closeAssignTeamsForm"
      >
        Cancel
      </ElementButton>
      <ElementButton
        type="primary"
        plain
        class="assign-teams__dialog-form__submit"
        :disabled="disableSubmitButton"
        :loading="isLoadingTeams"
        @click="submitForm"
      >
        Done
      </ElementButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AssignTeamsForm',

  props: {
    userTeams: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      isLoadingTeams: false,
      assignTeamsForm: {
        teamsList: []
      },
      validationRules: {
        teamsList: [
          {
            required: true,
            message: 'Please choose at least one team',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      teams: 'getTeams'
    }),

    /**
     * @returns {boolean}
     */
    disableSubmitButton () {
      return !this.assignTeamsForm.teamsList.length
    }
  },

  watch: {
    /**
     *
     */
    userTeams () {
      this.assignTeamsForm.teamsList = this.userTeams.map(t => t.id)
    }
  },

  async created () {
    this.assignTeamsForm.teamsList = this.userTeams.map(t => t.id)

    try {
      await this.fetchTeams()
    } catch (error) {
      if (error.response) {
        console.error('Something went wrong while fetching teams')
      } else {
        throw error
      }
    }
  },

  methods: {
    ...mapActions(['fetchTeams', 'changeUserTeams']),

    /**
     */
    closeAssignTeamsForm () {
      this.$emit('close-assign-teams-form')
    },

    /**
     */
    async submitForm () {
      this.isLoadingTeams = true

      await this.changeUserTeams({
        userId: this.userId,
        teamIds: this.assignTeamsForm.teamsList
      })

      this.isLoadingTeams = false
      this.assignTeamsForm.teamsList = []
      this.$emit('close-assign-teams-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-teams__dialog-buttons {
  text-align: center;
}

.assign-teams__dialog-form {
  width: spacing(50);
}

.assign-teams__dialog-form-item {
  /deep/ .el-form-item__label {
    padding: 0;
  }

  /deep/ .el-select {
    width: 100%;
  }
}
</style>
