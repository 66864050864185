<template>
  <ElementRow :gutter="18">
    <ElementCol :span="5">
      <div class="teams-table__actions">
        <h3>{{ activeTeam ? activeTeam.name : '' }}</h3>
        <div class="teams-table__info">
          <span v-if="usersCount === 1">{{ usersCount }} member</span>
          <span v-else-if="usersCount > 1">{{ usersCount }} members</span>
          <span v-else>no members</span>
        </div>
      </div>
      <ElementTable
        v-loading="isLoadingTeams"
        :data="
          mappedTeams.filter(
            data =>
              !searchValue ||
              data.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        "
        class="teams-table"
        empty-text="Teams not found."
        max-height="610"
        :cell-style="{ height: '55px', padding: '0px' }"
        :row-class-name="tableRowClassName"
      >
        <!-- eslint-disable -->
        <ElementTableColumn align="left">
          <template slot="header" slot-scope="scope">
            <ElementInput
              v-model="searchValue"
              size="mini"
              placeholder="Search"
              class="teams-table__search"
              clearable
            >
              <i slot="prefix" class="el-input__icon el-icon-search" />
            </ElementInput>
          </template>
          <template slot-scope="scope">
            <div
              class="teams-page__team-name"
              @click="handleTabChange(scope.row.id)"
            >
              {{ scope.row.name }}
            </div>
          </template>
        </ElementTableColumn>
      </ElementTable>
    </ElementCol>
    <ElementCol :span="19">
      <div class="grid-content bg-purple-light">
        <template v-if="activeTeam && activeTeam.isActive">
          <TeamUsersTable
            :team-id="activeTeam.id"
            :team-name="activeTeam.name"
          />
        </template>
      </div>
    </ElementCol>
  </ElementRow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TeamUsersTable from '@/components/users/TeamUsersTable.vue'

export default {
  name: 'TeamsPage',

  components: {
    TeamUsersTable
  },

  data () {
    return {
      tabPosition: 'left',
      isLoadingTeams: false,
      activeTeam: null,
      setTeam: null,
      mappedTeams: [],
      searchValue: ''
    }
  },

  computed: {
    ...mapGetters({
      teams: 'getTeams',
      usersCount: 'getUsersByTeamCount'
    })
  },

  async created () {
    this.isLoadingTeams = true

    try {
      await this.fetchTeams()
      this.mappedTeams = this.mapTeams(this.teams)
      this.activeTeam = this.mappedTeams[0]
      this.activeTeam.isActive = true
    } catch (error) {
      if (error.response) {
        // this.errorStatus = error.response.status
        console.log('Error fetching the teams')
      } else {
        throw error
      }
    }

    this.isLoadingTeams = false
  },

  methods: {
    ...mapActions(['fetchTeams']),

    /**
     * @param {number} id
     */
    handleTabChange (id) {
      this.mappedTeams = this.mapTeams(this.teams)
      const teamToDisplay = this.mappedTeams.find(t => t.id === id)
      teamToDisplay.isActive = true
      this.activeTeam = teamToDisplay
    },

    /**
     * @param   {Array} teams
     *
     * @returns {Array}
     */
    mapTeams (teams) {
      return teams.map(team => ({
        ...team,
        isActive: false
      }))
    },

    /**
     * @param   {object} row
     *
     * @returns {string}
     */
    tableRowClassName (row) {
      if (row.row.isActive) {
        return 'selected-row'
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.teams-page__tabs {
  height: spacing(92);
}

.teams-table__actions {
  font-weight: $font-weight-medium;
  font-size: spacing(3);
}

.teams-table__info {
  margin-bottom: spacing(1.6);
  color: $grey-light;
  font-weight: $font-weight-regular;
  font-size: spacing(1.75);
}

.teams-page__selection {
  padding-top: spacing(1.25);
}

.teams-table__search {
  height: spacing(7.5);
}

.teams-page__team-name {
  display: flex;
  align-items: center;
  height: spacing(6.75);
  font-weight: $font-weight-bold;
  cursor: pointer;
}
</style>

<style lang="scss">
$tabs-nav-width: 300px;
$color-white: #fff;

.el-tabs__nav {
  width: $tabs-nav-width !important;
}

.el-table .selected-row {
  color: $blue;
  background: $grey-ultra-light;
}

.el-table__header {
  height: spacing(7.5) !important;
}

.teams-table .el-table__body {
  max-height: spacing(80);
  overflow: scroll;
}

.teams-table .el-table__header th {
  position: relative;
  padding: 0 !important;
}

.teams-table .el-table__header .cell {
  padding: 0 !important;
}

.teams-table__search .el-input__prefix {
  top: spacing(-1.5) !important;
}

.teams-table__search .el-input__suffix {
  top: spacing(-1.25) !important;
}

.teams-table ::-webkit-scrollbar {
  width: spacing(0.25);
}

.teams-table ::-webkit-scrollbar-track {
  background-color: $color-white;
}

.teams-table ::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: spacing(0.75);
}

.teams-table ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
