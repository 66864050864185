<template>
  <ElementRow :gutter="18">
    <ElementCol :span="5">
      <template v-if="activeVendor && activeVendor.id === 'registrations'">
        <div class="vendors-table__actions">
          <h3>Awaiting Approval</h3>
          <div class="vendors-table__info">
            <span v-if="registrationsCount === 1">1 Request in the Queue</span>
            <span
              v-else-if="registrationsCount >= 1"
            >{{ registrationsCount }} Requests in the Queue</span>
            <span v-else>no pending requests</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="vendors-table__actions">
          <h3>{{ activeVendor ? activeVendor.name : '' }}</h3>
          <div class="vendors-table__info">
            <span v-if="usersCount === 1">{{ usersCount }} member</span>
            <span v-else-if="usersCount > 1">{{ usersCount }} members</span>
            <span v-else>no members</span>
          </div>
        </div>
      </template>
      <ElementTable
        v-loading="isLoadingVendors"
        :data="
          mappedVendors.filter(
            data =>
              !searchValue ||
              data.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        "
        class="vendors-table"
        empty-text="Vendors not found."
        max-height="610"
        :cell-style="{ height: '55px', padding: '0px' }"
        :row-class-name="tableRowClassName"
      >
        <!-- eslint-disable -->
        <ElementTableColumn align="left">
          <template slot="header" slot-scope="scope">
            <ElementInput
              v-model="searchValue"
              size="mini"
              placeholder="Search"
              class="vendors-table__search"
              clearable
            >
              <i slot="prefix" class="el-input__icon el-icon-search" />
            </ElementInput>
          </template>
          <template slot-scope="scope">
            <div
              class="vendors-page__team-name"
              @click="handleTabChange(scope.row.id)"
            >
              <template
                v-if="
                  scope.row.id === 'registrations' && registrationsCount > 0
                "
              >
                <ElementBadge :value="registrationsCount">
                  {{ scope.row.name }}
                </ElementBadge>
              </template>
              <template v-else>
                {{ scope.row.name }}
              </template>
            </div>
          </template>
        </ElementTableColumn>
      </ElementTable>
    </ElementCol>
    <ElementCol :span="19">
      <div class="grid-content bg-purple-light">
        <template v-if="activeVendor && activeVendor.isActive">
          <template v-if="activeVendor.id === 'registrations'">
            <RegistrationsTable />
          </template>
          <template v-else>
            <VendorUsersTable :vendor-id="activeVendor.id" />
          </template>
        </template>
      </div>
    </ElementCol>
  </ElementRow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RegistrationsTable from '@/components/vendors/RegistrationsTable.vue'
import VendorUsersTable from '@/components/vendors/VendorUsersTable.vue'

export default {
  name: 'VendorsPage',

  components: {
    RegistrationsTable,
    VendorUsersTable
  },

  data () {
    return {
      tabPosition: 'left',
      isLoadingVendors: false,
      activeVendor: null,
      setVendor: null,
      mappedVendors: [],
      searchValue: ''
    }
  },

  computed: {
    ...mapGetters({
      vendors: 'getVendors',
      usersCount: 'getUsersByVendorCount',
      registrationsCount: 'getRegistrationsCount'
    })
  },

  async created () {
    this.isLoadingVendors = true

    try {
      await this.fetchVendors()
      this.mappedVendors = this.mapVendors(this.vendors)
      this.activeVendor = this.mappedVendors[0]
      this.activeVendor.isActive = true
    } catch (error) {
      if (error.response) {
        // this.errorStatus = error.response.status
        console.log('Error fetching the vendors')
      } else {
        throw error
      }
    }

    this.isLoadingVendors = false
  },

  methods: {
    ...mapActions(['fetchVendors']),

    /**
     * @param {number} id
     */
    handleTabChange (id) {
      this.mappedVendors = this.mapVendors(this.vendors)
      const vendorToDisplay = this.mappedVendors.find(t => t.id === id)
      vendorToDisplay.isActive = true
      this.activeVendor = vendorToDisplay
    },

    /**
     * @param   {Array} vendors
     *
     * @returns {Array}
     */
    mapVendors (vendors) {
      const mappedVendors = vendors.map(vendor => ({
        ...vendor,
        isActive: false
      }))

      mappedVendors.unshift({
        id: 'registrations',
        name: 'New Registrations'
      })

      return mappedVendors
    },

    /**
     * @param   {object} row
     *
     * @returns {string}
     */
    tableRowClassName (row) {
      if (row.row.isActive && row.row.id !== 'registrations') {
        return 'selected-row'
      } else if (row.row.id === 'registrations') {
        return 'registrations-row'
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.vendors-page__tabs {
  height: spacing(92);
}

.vendors-table__actions {
  font-weight: $font-weight-medium;
  font-size: spacing(3);
}

.vendors-table__info {
  margin-bottom: spacing(1.6);
  color: $grey-light;
  font-weight: $font-weight-regular;
  font-size: spacing(1.75);
}

.vendors-page__selection {
  padding-top: spacing(1.25);
}

.vendors-table__search {
  height: spacing(7.5);
}

.vendors-page__team-name {
  display: flex;
  align-items: center;
  height: spacing(6.75);
  font-weight: $font-weight-bold;
  cursor: pointer;
}
</style>

<style lang="scss">
$tabs-nav-width: 300px;
$color-white: #fff;
$first-tr-zindex: 9999;

.el-tabs__nav {
  width: $tabs-nav-width !important;
}

.el-table .selected-row {
  color: $blue;
  background: $grey-ultra-light;
}

.el-table .registrations-row {
  color: $blue;
  background: #ecf5ff;
}

.el-table__header {
  height: spacing(7.5) !important;
}

.vendors-table .el-table__body {
  max-height: spacing(80);
  overflow: scroll;
}

.vendors-table .el-table__header th {
  position: relative;
  padding: 0 !important;
}

.vendors-table .el-table__header .cell {
  padding: 0 !important;
}

.vendors-table__search .el-input__prefix {
  top: spacing(-1.5) !important;
}

.vendors-table__search .el-input__suffix {
  top: spacing(-1.25) !important;
}

.vendors-table ::-webkit-scrollbar {
  width: spacing(0.25);
}

.vendors-table ::-webkit-scrollbar-track {
  background-color: $color-white;
}

.vendors-table ::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: spacing(0.75);
}

.vendors-table ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vendors-table tr:first-child {
  position: sticky;
  top: 0;
  z-index: $first-tr-zindex;
}

.vendors-page__team-name .el-badge__content {
  top: 0 !important;
}
</style>
