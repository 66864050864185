var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"users-table--actions"},[_c('div',{staticClass:"users-table-teams__total"},[(_vm.usersCount === 1)?_c('span',[_vm._v(_vm._s(_vm.usersCount)+" user")]):(_vm.usersCount > 1)?_c('span',[_vm._v(_vm._s(_vm.usersCount)+" users")]):_c('span',[_vm._v("no users")])]),_c('ElementInput',{ref:"searchInput",staticClass:"users-table-teams__search",attrs:{"placeholder":"Search","size":"small","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})]),_c('ElementButton',{staticClass:"users-table--add-user-button",attrs:{"type":"primary","plain":"","size":"small","icon":"el-icon-plus"},on:{"click":_vm.handleUserFormVisibility}},[_vm._v(" Add Users ")]),_c('ElementButton',{staticClass:"users-table--add-user-button",attrs:{"type":"primary","plain":"","size":"small","icon":"el-icon-download","title":"Excel export","loading":_vm.isExportingUsers},on:{"click":_vm.handleUsersExport}})],1),_c('ElementTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoadingUsers),expression:"isLoadingUsers"}],staticClass:"users-table--table",attrs:{"data":_vm.usersData,"empty-text":"Users not found.","border":"","stripe":""},on:{"sort-change":_vm.sortUsers}},[_c('ElementTableColumn',{attrs:{"label":"Email","prop":"email","sortable":"custom","width":"240","empty-text":"There's no users available."}}),_c('ElementTableColumn',{attrs:{"label":"User Type","prop":"isHubAdmin","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ElementTag',{attrs:{"type":scope.row.isStaff
              ? 'danger'
              : scope.row.isHubAdmin
                ? 'warning'
                : 'info',"size":"mini","disable-transitions":""}},[_vm._v(" "+_vm._s(scope.row.isStaff ? 'Staff' : scope.row.isHubAdmin ? 'Hub Admin' : 'User')+" ")])]}}])}),_c('ElementTableColumn',{attrs:{"label":"Status","prop":"isActive","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.edit !== scope.row.id)?[_c('ElementTag',{attrs:{"type":scope.row.isActive ? 'success' : 'danger',"size":"mini","disable-transitions":""}},[_vm._v(" "+_vm._s(scope.row.isActive ? 'Active' : 'Inactive')+" ")])]:_c('ElementSelect',{attrs:{"size":"mini"},model:{value:(_vm.userModel.isActive),callback:function ($$v) {_vm.$set(_vm.userModel, "isActive", $$v)},expression:"userModel.isActive"}},_vm._l((_vm.USER_ACTIVITY),function(item){return _c('ElementOption',{key:("" + (item.label)),attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('ElementTableColumn',{attrs:{"label":"Role","prop":"role","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.edit !== scope.row.id ||
              scope.row.role.name === _vm.USER_ROLE.VENDOR.name
          )?[_vm._v(" "+_vm._s(scope.row.role.display_name)+" ")]:_c('ElementSelect',{attrs:{"size":"mini"},model:{value:(_vm.userModel.roleId),callback:function ($$v) {_vm.$set(_vm.userModel, "roleId", $$v)},expression:"userModel.roleId"}},_vm._l((_vm.roles),function(role){return _c('ElementOption',{key:role.id,attrs:{"label":role.display_name,"value":role.id}})}),1)]}}])}),_c('ElementTableColumn',{attrs:{"label":"Team(s)","prop":"teams","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.edit !== scope.row.id ||
              scope.row.role.name === _vm.USER_ROLE.VENDOR.name
          )?[_vm._v(" "+_vm._s(_vm.formatTeams(scope.row.teams))+" ")]:_c('p',{staticClass:"users-table--teams users-table--teams-hoverable",on:{"click":function($event){return _vm.handleAssignTeamFormVisibility(scope.row.id)}}},[_vm._v(" "+_vm._s(_vm.formatTeams(scope.row.teams))+" "),_c('i',{staticClass:"el-icon-edit"})])]}}])}),_c('ElementTableColumn',{attrs:{"label":"Last Login","prop":"lastLogin","width":"170"}}),_c('ElementTableColumn',{attrs:{"label":"Date Created","prop":"dateJoined","width":"170"}}),_c('ElementTableColumn',{attrs:{"align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.edit !== scope.row.id)?_c('ElementButton',{staticClass:"email-editor__add el-input__icon",attrs:{"disabled":(_vm.edit && _vm.edit !== scope.row.id) ||
              scope.row.isHubAdmin ||
              scope.row.isStaff,"type":"text","size":"mini"},on:{"click":function($event){return _vm.onEdit(scope.row.id)}}},[_c('i',{staticClass:"el-icon-edit"})]):_c('ElementButton',{staticClass:"email-editor__add el-input__icon",attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.onSave(scope.row.id)}}},[_c('i',{staticClass:"el-icon-check"})]),(_vm.edit === scope.row.id)?_c('ElementButton',{attrs:{"type":"text","size":"mini"},on:{"click":_vm.onCancel}},[_c('i',{staticClass:"el-icon-close"})]):_c('ElementButton',{attrs:{"disabled":(_vm.edit && _vm.edit !== scope.row.id) ||
              scope.row.isHubAdmin ||
              scope.row.isStaff,"type":"text","size":"mini"},on:{"click":function($event){return _vm.onDelete(scope.row.id)}}},[_c('i',{staticClass:"el-icon-delete"})])]}}])},[_c('template',{slot:"header"},[_vm._v(" Actions ")])],2)],1),_c('ElementPagination',{attrs:{"align":"right","layout":"prev, pager, next","total":_vm.usersCount,"per-page":_vm.limit,"current-page":_vm.currentPage},on:{"current-change":_vm.setPage}}),_c('StitchDialog',{staticClass:"assign-teams__dialog",attrs:{"title":"Assign Teams","visible":_vm.showAssignTeamsForm,"show-close":true,"append-to-body":"","center":"","close-on-click-modal":true,"close-on-press-escape":true},on:{"update:visible":function($event){_vm.showAssignTeamsForm=$event}}},[_c('AssignTeamsForm',{attrs:{"user-teams":_vm.userTeamsModel,"user-id":_vm.userIdToAssign},on:{"close-assign-teams-form":_vm.handleAssignTeamFormVisibility}})],1),_c('StitchDialog',{staticClass:"add-new-invitation__dialog",attrs:{"title":"Invite new users","visible":_vm.showNewInvitationForm,"show-close":true,"append-to-body":"","center":"","close-on-click-modal":true,"close-on-press-escape":true},on:{"update:visible":function($event){_vm.showNewInvitationForm=$event}}},[_c('AddUserForm',{on:{"close-invitation-form":_vm.handleUserFormVisibility}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }