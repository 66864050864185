<template>
  <div>
    <div class="users-table--actions">
      <ElementInput
        ref="searchInput"
        v-model="query"
        placeholder="Search"
        size="small"
        clearable
        class="users-table-vendors__search"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
        />
      </ElementInput>
    </div>

    <ElementTable
      v-loading="isLoadingRegistrations"
      :data="registrations"
      border
      stripe
      class="users-table"
      empty-text="Users not found."
      @sort-change="sortUsers"
    >
      <ElementTableColumn
        label="Email"
        prop="email"
        sortable
      />

      <ElementTableColumn
        label="Requested Vendor Company"
        prop="requested_vendor_company"
      />

      <ElementTableColumn
        label="Vendor Company"
        prop="role"
      >
        <template slot-scope="scope">
          <template v-if="edit !== scope.row.email">
            <ElementTag
              type="danger"
              size="small"
            >
              Not Assigned
            </ElementTag>
          </template>
          <ElementSelect
            v-else
            v-model="vendorUserModel.vendorCompanyId"
            size="mini"
            filterable
            clearable
          >
            <ElementOption
              v-for="company in vendors"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        align="center"
        width="160"
      >
        <template slot="header">
          Actions
        </template>
        <template slot-scope="scope">
          <ElementButton
            v-if="edit !== scope.row.email"
            :disabled="edit && edit !== scope.row.email"
            type="text"
            size="mini"
            class="email-editor__add el-input__icon"
            @click="onEdit(scope.row.email)"
          >
            <i class="el-icon-edit" />
          </ElementButton>
          <template v-else>
            <ElementButton
              :disabled="
                edit &&
                  edit === scope.row.email &&
                  !vendorUserModel.vendorCompanyId
              "
              type="text"
              size="mini"
              class="email-editor__add el-input__icon"
              @click="onSave(scope.row.email)"
            >
              <i class="el-icon-check" />
            </ElementButton>
            <ElementButton
              v-if="edit === scope.row.email"
              type="text"
              size="mini"
              @click="onCancel"
            >
              <i class="el-icon-close" />
            </ElementButton>
          </template>
        </template>
      </ElementTableColumn>
    </ElementTable>

    <ElementPagination
      align="right"
      layout="prev, pager, next"
      :total="registrationsCount"
      :per-page="5"
      :current-page="currentPage"
      @current-change="setPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SORTING_TYPE } from '@/constants/filterType'

export default {
  name: 'RegistrationsTable',

  components: {},

  data () {
    return {
      edit: null,
      query: '',
      isLoadingRegistrations: false,
      showActiveSelect: false,
      showVendorsModal: false,
      vendorUserModel: {
        email: null,
        vendorCompanyId: null
      },
      currentPage: 1,
      sorting: null
    }
  },

  computed: {
    ...mapGetters({
      registrations: 'getRegistrations',
      registrationsCount: 'getRegistrationsCount',
      vendors: 'getVendors'
    })
  },

  watch: {
    /**
     */
    async query () {
      this.isLoadingRegistrations = true
      await this.fetchRegistrations({
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = 1
      this.isLoadingRegistrations = false
    }
  },

  async created () {
    this.isLoadingRegistrations = true

    try {
      await this.fetchRegistrations({ page: 1, sorting: this.sorting })
      await this.fetchVendors()
    } catch (error) {
      if (error.response) {
        console.log('Error fetching the registrations')
      } else {
        throw error
      }
    }

    this.isLoadingRegistrations = false
  },

  methods: {
    ...mapActions([
      'fetchRegistrations',
      'fetchVendors',
      'assignVendorUserToCompany'
    ]),

    /**
     * @param {number} vendorEmail
     */
    onEdit (vendorEmail) {
      const vendorToEdit = this.registrations.find(
        vendor => vendor.email === vendorEmail
      )

      this.vendorUserModel = {
        email: vendorToEdit.email,
        vendorCompanyId: this.vendorUserModel.vendorCompanyId
      }

      this.edit = this.edit !== vendorEmail ? vendorEmail : null
    },

    /**
     *
     */
    async onSave () {
      await this.assignVendorUserToCompany(this.vendorUserModel)
      this.onCancel()
    },

    /**
     *
     */
    onCancel () {
      this.vendorUserModel = {
        email: null,
        vendorCompanyId: null
      }

      this.edit = null
    },

    /**
     *
     * @param { number } page
     */
    async setPage (page) {
      this.isLoadingRegistrations = true
      await this.fetchRegistrations({
        page,
        query: this.query,
        sorting: this.sorting
      })
      this.currentPage = page
      this.isLoadingRegistrations = false
    },

    /**
     * @param {string} prop
     * @param {string} order
     */
    async sortUsers ({ prop, order }) {
      this.sorting = order
        ? order === SORTING_TYPE.ASC
          ? prop
          : `-${prop}`
        : null

      this.isLoadingRegistrations = true
      await this.fetchRegistrations({
        page: this.currentPage,
        query: this.query,
        sorting: this.sorting
      })
      this.isLoadingRegistrations = false
    }
  }
}
</script>

<style lang="scss" scoped>
$users-table-vendors-column-width: 250px;
$content-header-search-width: 210px;
$content-header-input-min-width: spacing(12);
$button-small-padding: 10px 15px;
$title-top: 12px;
$users-table-margin-top: 40px;
$total-members-border-radius: 8px;
$total-members-font-size: 14px;
$total-members-text-color: #909399;
$total-members-bg-color: #f0f2f5;

.users-table {
  width: 100%;
  height: 100%;
  margin-top: $users-table-margin-top;
}

.users-table--vendors {
  max-width: $users-table-vendors-column-width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users-table__action-edit {
  display: inline;
  color: $blue;
}

.users-table__action-delete {
  display: inline;
  color: $red;
}

.add-new-invitation__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.assign-vendors__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.users-table--actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.users-table--actions h3 {
  margin-right: auto;
  font-weight: $font-weight-medium;
  font-size: spacing(3);
}

.users-table-vendors__total {
  position: absolute;
  margin-top: spacing(8);
  margin-left: spacing(1);
  color: $total-members-text-color;
  font-size: $total-members-font-size;
}

.users-table-vendors__search {
  width: auto;
  max-width: $content-header-search-width;
  margin-right: spacing(1);
  margin-left: auto;

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }
}
</style>
