<template>
  <div class="admin-tools">
    <div class="admin-tools__container">
      <ElementTabs
        v-model="activeTab"
        class="plugins__navigation"
        data-testid="pluginNav"
        type="border-card"
      >
        <ElementTabPane
          v-if="user.is_hub_admin"
          label="Users"
          name="users"
        >
          <h1 class="admin-tools-header__title">
            Manage Users
          </h1>
          <br>
          <UsersTable />
        </ElementTabPane>
        <ElementTabPane
          label="Teams"
          name="teams"
        >
          <h1 class="admin-tools-header__title">
            Manage Teams
          </h1>
          <br>
          <TeamsPage />
        </ElementTabPane>
        <ElementTabPane
          v-if="user.is_hub_admin"
          name="vendors"
        >
          <template #label>
            <ElementBadge
              v-if="registrationsCount > 0 && activeTab !== 'vendors'"
              :value="registrationsCount"
            >
              Vendors
            </ElementBadge>
            <span v-else> Vendors </span>
          </template>
          <h1 class="admin-tools-header__title">
            Manage Vendors
          </h1>
          <br>
          <VendorsPage />
        </ElementTabPane>
      </ElementTabs>
    </div>
  </div>
</template>

<script>
import UsersTable from '@/components/users/UsersTable.vue'
import TeamsPage from '@/components/users/TeamsPage.vue'
import VendorsPage from '@/components/vendors/VendorsPage.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AdminTools',

  components: {
    TeamsPage,
    UsersTable,
    VendorsPage
  },

  data: () => ({
    activeTab: 'vendors'
  }),

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData',
      registrationsCount: 'getRegistrationsCount'
    }),

    /**
     * @returns {boolean}
     */
    showUsersTab () {
      return this.user.is_hub_admin
    }
  },

  created () {
    this.activeTab = this.showUsersTab ? 'users' : 'teams'
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
$title-top: 12px;

.admin-tools {
  height: 100%;
}

.admin-tools__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.admin-tools__container::-webkit-scrollbar {
  display: none;
}

.admin-tools-header__title {
  position: relative;
  top: $title-top;
  margin-bottom: spacing(2);
  padding-right: spacing(2);
  color: $grey-dark;

  @media (max-width: $media-query-s) {
    display: none;
  }
}
</style>

<style lang="scss">
.admin-tools .el-badge__content {
  top: spacing(1.1);
  font-size: spacing(1.2);
}
</style>
