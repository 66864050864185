<template>
  <div>
    <ElementForm
      ref="newInvitationForm"
      label-position="top"
      class="add-form"
      :model="newInvitationForm"
      :rules="validationRules"
      hide-required-asterisk
    >
      <ElementFormItem
        placeholder="Select users"
        label="Users"
        prop="users"
        class="add-form__item"
      >
        <ElementSelect
          v-model="newInvitationForm.email"
          filterable
          placeholder="Select a user"
          :filter-method="searchForUsers"
          :loading="isLoadingUsers"
        >
          <ElementOption
            v-for="user in filteredUsers"
            :key="user.id"
            :label="user.email"
            :value="user.email"
          />
        </ElementSelect>
      </ElementFormItem>
    </ElementForm>
    <div
      slot="footer"
      class="add-form__buttons"
    >
      <ElementButton
        plain
        class="add-form__cancel"
        @click="closeNewInvitationForm"
      >
        Cancel
      </ElementButton>
      <ElementButton
        type="primary"
        plain
        class="add-form__submit"
        :disabled="disableSubmitButton"
        @click="submitForm"
      >
        Done
      </ElementButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddUserToTeamForm',

  props: {
    existingUsers: {
      type: Array,
      required: true
    },

    currentTeamId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      searchText: '',
      editorEmail: '',
      isLoadingUsers: false,
      newInvitationForm: {
        email: null,
        teamId: null
      },
      validationRules: {}
    }
  },

  computed: {
    ...mapGetters({
      users: 'getUsers'
    }),

    /**
     * @returns {object[]}
     */
    filteredUsers () {
      return this.users.filter(
        user =>
          !this.existingUsers.some(existUser => user.id === existUser.id) &&
          user.isActive
      )
    },

    /**
     * @returns {boolean}
     */
    disableSubmitButton () {
      return !this.newInvitationForm.email
    }
  },

  async created () {
    this.isLoadingUsers = true
    await this.fetchUsers({ page: 1, limit: 20 })
    this.isLoadingUsers = false
  },

  methods: {
    ...mapActions(['inviteUser', 'fetchUsers']),

    /**
     */
    async submitForm () {
      this.newInvitationForm.teamId = this.currentTeamId
      await this.inviteUser(this.newInvitationForm)
      this.closeNewInvitationForm()
    },

    /**
     */
    closeNewInvitationForm () {
      this.newInvitationForm.email = null
      this.$emit('close-invitation-form')
    },

    /**
     * @param { string } query
     */
    async searchForUsers (query) {
      this.isLoadingUsers = true
      await this.fetchUsers({ query: query, limit: 20 })
      this.isLoadingUsers = false
    }
  }
}
</script>

<style lang="scss" scoped>
$max-height-editor: 300px;
$gap-emails: spacing(1/2);
$top-email-tag-close: 1px;
$padding-email-add-button: 9px 6px;

.add-form {
  width: spacing(50);
}

.add-form__item {
  /deep/ .el-form-item__label {
    padding: 0;
  }

  /deep/ .el-select {
    width: 100%;
  }
}

.add-form__buttons {
  text-align: center;
}

.email-editor {
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: spacing(5);
  max-height: $max-height-editor;
  padding: $gap-emails;
}

.email-editor__emails {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  overflow: auto;
  border: $gap-emails solid transparent;
}

.email-editor__email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 0 spacing(1) spacing(1) 0;

  /deep/ .el-tag__close {
    top: $top-email-tag-close;
  }
}

.email-editor__input {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  padding: spacing(1/2) 0;
  font-size: inherit;
}

.email-editor__add {
  position: absolute;
  top: spacing(1);
  right: spacing(1/2);
  width: auto;
  height: fit-content;
  padding: $padding-email-add-button;
  background: $white;
}
</style>
